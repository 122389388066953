import React, { useEffect, useState } from 'react'
import { getRentalsFromCalendar } from '../../gateways/googleCalendar'
import LoadingIndicator from '../LoadingIndicator'
import s from './rental.module.css'

const RentalView = () => {
  const [rentals, setRentals] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getRentalsFromCalendar()
      .then((rentals) => {
        setRentals(rentals)
        setLoading(false)
      })
      .catch(console.error)
  }, [])
  const rows = rentals.map((rental) => (
    <tr key={rental.start}>
      <td>{rental.title}</td>
      <td>{rental.start}</td>
      <td>{rental.end}</td>
    </tr>
  ))

  const loader = loading ? <LoadingIndicator /> : null

  return (
    <div className={s.rentalView}>
      <table className={s.rentalTable}>
        <thead>
          <tr>
            <th></th>
            <th>Aankomst</th>
            <th>Vertrek</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
      {loader}
    </div>
  )
}

export default RentalView
