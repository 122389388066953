import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MenuItem from '../MenuItem'
import { menu } from '../../config/menu'

const headerPosition = {
  position: 'fixed',
  top: '0%',
  width: '100%',
  maxWidth: '1140px',
  paddingRight: '32px',
  paddingLeft: '20px',
  zIndex: 900
}

export default function Header(props) {
  const [navExpanded, setNavExpanded] = React.useState(false)

  const closeNav = () => {
    setNavExpanded(false)
  }

  const menuItems = menu.map((item) => (
    <MenuItem
      key={item.id}
      onClick={closeNav}
      menu={item}
    />
  ))

  const navScrollStyle = {
    overflowY: 'auto',
    maxHeight: `${window.innerHeight * 0.8}px`
  }

  const navLinkProps = (link) => ({
    className: ({ isActive }) => (isActive ? 'nav-link active' : 'nav-link'),
    onClick: closeNav,
    to: link
  })

  return (
    <Navbar
      bg='dark'
      variant='dark'
      expand='lg'
      onToggle={setNavExpanded}
      expanded={navExpanded}
      style={headerPosition}
    >
      <NavLink
        to='/home'
        onClick={closeNav}
      >
        <Navbar.Brand>
          <FontAwesomeIcon icon='home' />
        </Navbar.Brand>
      </NavLink>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />

      <Navbar.Collapse
        id='basic-navbar-nav'
        className='bg-dark'
      >
        <Nav
          className='mr-auto'
          onSelect={closeNav}
          style={navScrollStyle}
        >
          <NavLink {...navLinkProps('/home')}>Home</NavLink>
          {menuItems}
          <NavLink {...navLinkProps('/photos#top')}>Fotogalerij</NavLink>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
