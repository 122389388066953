import React, { useEffect } from 'react'
import './App.css'
import Home from './Home'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome } from '@fortawesome/free-solid-svg-icons'

import 'bootstrap/dist/css/bootstrap.css'
import GlobalStyle from './global-styles'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Footer from './components/Footer'
import styled from 'styled-components'
import Header from './components/Header'
import ContentPage from './ContentPage'
import PhotoGallery from './PhotoGallery'

library.add(faHome)

const AppWrapper = styled.div`
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  padding: 0 16px;
  flex-direction: column;
`

// const scrollToNav = () => {
//   let hash = location.hash
//   if (hash) {
//     hash = hash.substring(1)
//   }
//   const elem = document.getElementById(hash)
//   if (elem) {
//     elem.scrollIntoView({ behavior: 'smooth', block: 'start' })
//   }
// }

function App() {
  return (
    <div id='app'>
      <GlobalStyle />
      <AppWrapper>
        <BrowserRouter basename='/'>
          <Header />
          <Routes>
            <Route
              path='/'
              element={<Home />}
            />
            <Route
              path='/home'
              element={<Home />}
            />
            <Route
              path='/photos'
              element={<PhotoGallery />}
            />
            <Route
              path='/content/:page'
              element={<ContentPage />}
            />
            <Route
              path='*'
              element={<Home />}
            />
          </Routes>
        </BrowserRouter>
        <Footer />
      </AppWrapper>
    </div>
  )
}

export default App
