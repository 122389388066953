import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

function MenuItem(props) {
  const { menu } = props

  let contentRoot
  let to
  if (props.contentRoot) {
    contentRoot = `${props.contentRoot}${menu.id}`
    to = contentRoot
  } else {
    contentRoot = `/content/${menu.id}#`
    to = contentRoot + 'top'
  }

  const subItems = menu.sub || []
  const subMenu = subItems.map((sub) => (
    <li key={contentRoot + '/' + sub.id}>
      <MenuItem
        onClick={props.onClick}
        menu={sub}
        contentRoot={contentRoot}
      />
    </li>
  ))

  const showSubMenu = subItems.length > 1
  return (
    <span>
      <NavLink
        className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
        to={to}
        onClick={props.onClick}
      >
        {menu.title}
      </NavLink>
      {showSubMenu && <ul className='d-lg-none'>{subMenu}</ul>}
    </span>
  )
}

MenuItem.propTypes = {
  menu: PropTypes.any,
  contentRoot: PropTypes.string,
  onClick: PropTypes.func
}

export default MenuItem
