import request from '../utils/request'
import { extractContentFromFlickrResponse } from './flickrConverter'

const flickrHost = 'https://api.flickr.com/services/rest/'
const apiKey = 'ba7be564ff5ccb76f59ef551b65ebe76'
const userId = '7402523%40N05'
const extras = 'o_dims,url_h,url_n,url_c,url_z,url_l,url_o,url_s'

export async function getPhotoSet(photoSetId) {
  const url = `${flickrHost}?method=flickr.photosets.getPhotos&api_key=${apiKey}&photoset_id=${photoSetId}&user_id=${userId}&extras=${extras}&format=json&nojsoncallback=1`
  const flickResponse = await request(url).getJson()
  return extractContentFromFlickrResponse(flickResponse)
}

export function getPhotoSets() {
  const url = `${flickrHost}?method=flickr.photosets.getList&api_key=${apiKey}&user_id=${userId}&format=json&nojsoncallback=1`
  return request(url).getJson()
}
