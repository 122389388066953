export const menu = [
  {
    id: 'het-huisje',
    title: 'Het Huisje',
    sub: [
      { title: 'Het huisje', id: 'algemeen' },
      { title: 'Interieur', id: 'interieur' },
      { title: 'Voorzieningen', id: 'voorzieningen' },
      { title: 'Adres & Route', id: 'adres-en-route' }
    ]
  },
  {
    id: 'omgeving',
    title: 'Omgeving',
    sub: [
      { title: 'Plattegrond', id: 'plattegrond' },
      { title: 'Oberhundem', id: 'oberhundem' },
      { title: 'Activiteiten', id: 'activiteiten' },
      { title: 'Voorzieningen', id: 'voorzieningen' },
      { title: 'Panoramapark', id: 'panoramapark' }
    ]
  },
  {
    id: 'buitensport',
    title: 'Buitensport',
    sub: [
      { title: 'Wandelen', id: 'wandelen' },
      { title: 'Fietsen', id: 'fietsen' },
      { title: 'Skiën', id: 'ski' },
      { title: 'Langlaufen', id: 'langlaufen' },
      { title: 'Klimmen', id: 'klimmen' }
    ]
  },
  {
    id: 'verhuur',
    title: 'Verhuur',
    sub: [{ title: 'Beschikbaarheid', id: 'beschikbaarheid' }]
  },
  {
    id: 'informatie',
    title: 'Informatie',
    sub: [
      { title: 'Informatie', id: 'informatie' },
      { title: 'Weersverwachting', id: 'weersverwachting' },
      { title: 'Sneeuwverwachting', id: 'sneeuwverwachting' }
    ]
  },
  {
    id: 'contact',
    title: 'Contact',
    sub: [{ title: 'Contact', id: 'contact' }]
  }
]
