import React, { useEffect } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import MarkdownPage from '../components/MarkdownPage'
import Gumshoe from 'gumshoejs'
import { Container, Col, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { menu } from '../config/menu'

import s from './content.module.css'

const sideMenuPostion = {
  position: 'fixed',
  top: '110px'
}
const positionStatic = { position: 'static' }

const ContentPage = () => {
  const { page } = useParams()
  const { hash } = useLocation()

  const thisPage = menu.find((item) => item.id === page)
  if (!thisPage) {
    return <div>Page not found</div>
  }

  useEffect(() => {
    scrollToNav()
    new Gumshoe('#gumshoe-root-element a', { offset: 100, navClass: s.gumshoeActive })
  }, [hash, page])

  const content = thisPage.sub.map((item) => ({ id: item.id, title: item.title, resp: item.title }))

  // const scrollIntoView = (el) => {
  //   console.log('scrollIntoView')
  //   el.scrollIntoView({ behavior: 'smooth', block: 'center' })
  // }

  const markDownOnLoad = () => scrollToNav()

  const scrollToNav = () => {
    let hash = location.hash
    if (hash) {
      hash = hash.substring(1)
    }
    const elem = document.getElementById(hash)
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const renderMarkdown = (content) =>
    content.map((section, idx) => (
      <div
        key={idx}
        id={section.id}
        style={{ scrollMarginTop: '80px' }}
      >
        <h2>{section.title}</h2>
        <MarkdownPage
          page={page}
          section={section.id}
          onLoad={markDownOnLoad}
        />
        <hr />
      </div>
    ))

  const SideMenu = ({ content }) => (
    <nav>
      <ul
        className={s.gumshoeRoot}
        id='gumshoe-root-element'
      >
        {content.map((section, idx) => (
          <li key={idx}>
            <NavLink
              className={s.sideMenuNav}
              to={'#' + section.id}
            >
              {section.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )

  return (
    <Container style={{ marginTop: '79px' }}>
      <Row>
        <Col
          md='0'
          lg='2'
          className='d-none d-lg-block'
          style={positionStatic}
        >
          <aside style={sideMenuPostion}>
            <SideMenu content={content} />
          </aside>
        </Col>
        <Col
          style={positionStatic}
          lg='10'
          className={s.markdownContent}
        >
          <span
            className={s.scrollAnchor}
            id='top'
          />
          {renderMarkdown(content)}
          <hr />
        </Col>
      </Row>
    </Container>
  )
}

ContentPage.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  content: PropTypes.any,
  page: PropTypes.array,
  menu: PropTypes.any
}

export default ContentPage
