import React from 'react'
import GoogleMapReact from 'google-map-react'
import marker from './spotlight-poi2.png' // https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png
import markerGreen from './icon_green.png'
import s from './tooltip.module.css'

const Marker = (props) => (
  <div style={{ width: '30px', height: '30px' }}>
    <img
      className={s.tooltipOnHover}
      src={props.marker === 'green' ? markerGreen : marker}
    />
    <div className={s.tooltip2}>
      <div className={s.innerTooltip}>{props.children}</div>
    </div>
  </div>
)

// https://stackoverflow.com/questions/34423644/tooltip-div-with-reactjs

const createMapOptions = function (maps) {
  return {
    panControl: true,
    mapTypeControl: true,
    scrollwheel: true
  }
}

class GMap extends React.Component {
  constructor(props) {
    super(props)
  }

  static defaultProps = {
    markers: []
  }

  render() {
    const center = {
      lat: this.props.lat,
      lng: this.props.lng
    }

    const markers = this.props.markers.map((m) => (
      <Marker
        key={m.lat}
        {...m}
      >
        {m.name}
      </Marker>
    ))

    // https://github.com/google-map-react/google-map-react/pull/1224
    // disable strict mode is a temporary fix
    return (
      <div style={{ width: '100%', height: '600px' }}>
        <GoogleMapReact
          defaultCenter={center}
          defaultZoom={this.props.zoom}
          bootstrapURLKeys={{ key: 'AIzaSyAEaKdQouJ7bzYoYe7qRXaMKZptyvqGoDg' }}
          language='nl'
          region='nl'
          options={createMapOptions}
        >
          {markers}
        </GoogleMapReact>
      </div>
    )
  }
}

export default GMap
