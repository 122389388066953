/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import house from './house3.jpg'
import rent from './rent2.jpg'
import album from './album3.jpg'
import { SimpleSlider } from '../components/Slider'
import A from '../components/A'
import s from './home.module.css'

export default function Home() {
  const navigate = useNavigate()

  const ArticleColumn = (props) => (
    <Col
      md='4'
      className={s.articleCol}
    >
      <div
        className={s.headerArticle2}
        onClick={() => navigate(props.page + '#top')}
      >
        <img src={props.img} />
        <article>{props.children}</article>
        <div className={s.homePageLinkWrapper}>
          <span className={s.visitMeLabel}>Bekijk nu &gt;</span>
        </div>
      </div>
    </Col>
  )

  const PhotoColumn = (props) => (
    <Col
      md='4'
      className={s.articleCol}
    >
      <div
        className={s.photoCell}
        onClick={() => navigate(props.page + '#top')}
      >
        <img src={props.img} />
        <article>{props.children}</article>
        <div className={s.homePageLinkWrapper}>
          <span className={s.visitMeLabel}>Bekijk nu &gt;</span>
        </div>
      </div>
    </Col>
  )

  return (
    <article>
      <Container style={{ marginTop: '55px' }}>
        <Row className={s.headerRow}>
          <Col
            lg='7'
            md='12'
          >
            <h1 className={s.headerTitle}>
              Ons vakantiehuis
              <br />
              in Sauerland
            </h1>
          </Col>
          <Col className='d-none d-lg-block'>
            <div className={s.sliderBlock}>
              <SimpleSlider />
            </div>
          </Col>
        </Row>

        <Row>
          <PhotoColumn
            img={house}
            page='/content/het-huisje'
          >
            Het Huisje
          </PhotoColumn>

          <ArticleColumn
            img={rent}
            page='/content/verhuur'
          >
            Wij verhuren het huisje graag aan familie, vrienden en bekenden.
          </ArticleColumn>

          <PhotoColumn
            img={album}
            page='/photos'
          >
            Foto's
          </PhotoColumn>
        </Row>

        <Row>
          <Col md='12'>
            {/* TODO move className to A component */}
            <A
              className={s.homePageLink}
              href='mailto:?subject=Huisje in Sauerland&amp;body=http://www.huisjeInSauerland.nl'
            >
              Breng een vriend op de hoogte van dit huisje
            </A>
            <br />
            <A
              href='http://www.facebook.com/HuisjeInSauerland'
              target='_facebook'
              className={s.homePageLink}
            >
              Bezoek ons ook op Facebook
            </A>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </article>
  )
}
