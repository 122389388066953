import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import FlickrPhotosContainer from '../components/FlickrPhotosContainer'

export default function PhotoGallery() {
  return (
    <article>
      <Container style={{ marginTop: '75px' }}>
        <span id='top' />
        <Row>
          <Col>
            <h2 style={{ marginBottom: '20px' }}>Fotogalerij</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <FlickrPhotosContainer />
          </Col>
        </Row>
      </Container>
    </article>
  )
}
