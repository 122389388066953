import React from 'react'
import Slider from 'react-slick'

import w1 from './slides/winter/foto1.jpg'
import w2 from './slides/winter/foto2.jpg'
import w3 from './slides/winter/foto3.jpg'
import w4 from './slides/winter/foto4.jpg'
import w5 from './slides/winter/foto5.jpg'

import s1 from './slides/summer/foto1.jpg'
import s2 from './slides/summer/foto2.jpg'
import s3 from './slides/summer/foto3.jpg'
import s4 from './slides/summer/foto4.jpg'
import s5 from './slides/summer/foto5.jpg'

import { calculateSummerWinter } from '../../utils/dateSupport'

const winter = [w1, w2, w3, w4, w5]
const summer = [s1, s2, s3, s4, s5]

const slide = (idx, src) => (
  <div key={idx}>
    <img src={src} />
  </div>
)

export const SimpleSlider = () => {
  const season = calculateSummerWinter()
  const imgSources = season === 'winter' ? winter : summer
  const slides = imgSources.map((i, idx) => slide(idx, i))
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 300,
    autoplaySpeed: 2500
  }
  return <Slider {...settings}>{slides}</Slider>
}
