import React, { useEffect, useState } from 'react'
import FlickrPhotoSet from './FlickrPhotoSet'
import LazyLoad from 'react-lazyload'
import { getPhotoSets } from '../../gateways/flickr'

export default function FlickrPhotosContainer() {
  const [photoSets, setPhotoSets] = useState([])

  useEffect(() => {
    const sets = getPhotoSets()
      .then((sets) => {
        const result = sets?.photosets?.photoset || []
        setPhotoSets(result)
      })
      .catch(console.error)
  }, [])

  return (
    <div>
      {photoSets.map((set) => (
        <div
          key={set.id}
          style={{ minHeight: '265px' }}
        >
          <LazyLoad height={250}>
            <FlickrPhotoSet setId={set.id} />
          </LazyLoad>
        </div>
      ))}
    </div>
  )
}
