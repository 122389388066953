/**
 * ReactMarkdown wrapper. Does a number of things:
 * - Gives paragraphs a class if first child of the paragrafh is something like: {: .float-right .img-right}
 * - calls callback onLoad for every event that will change the Dom structure (length of page for example)
 * - gets images from the correct folder from the content repository (github raw documents)
 */

import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { NavLink } from 'react-router-dom'
import config from '../../config'
import { getPage } from '../../gateways/github'
import GMap from '../../components/GMap'
import { calculateSummerWinter } from '../../utils/dateSupport'
import PropTypes from 'prop-types'
import RentalView from '../RentalView'
import s from './markdown.module.css'

const SEASON = calculateSummerWinter()

/**
 * Factory for components that can be used inside the content written in markdown
 * @param str
 * @returns {*}
 */
function componentFactory(str) {
  if (str === 'FeriendorfMap') {
    const p = {
      lat: 51.0789,
      lng: 8.1768,
      zoom: 15,
      markers: [{ lat: 51.0789, lng: 8.1768, name: 'Feriendorf 4' }]
    }
    return <GMap {...p} />
  }

  if (str === 'ClimbingMap') {
    const kletterArenaLink = (url, name) => {
      const link = `http://www.kletterarena.info/${url}.php`
      return (
        <a
          href={link}
          target='_blank'
        >
          {name}
        </a>
      )
    }

    const p = {
      lat: 51.24,
      lng: 8.18,
      zoom: 9,
      markers: [
        { lat: 51.0789, lng: 8.1768, name: 'Feriendorf 4', marker: 'green' },
        { lat: 51.375376, lng: 7.8603642, name: kletterArenaLink('hoennetal', 'Hönnetal') },
        { lat: 51.262173, lng: 7.7567593, name: kletterArenaLink('klettergebiet_werdohl', 'Werdohler Lenneplatte') },
        { lat: 51.151421, lng: 7.9574343, name: kletterArenaLink('elberskamp', 'Unterer Elberskamp') },
        { lat: 51.088989, lng: 7.8088821, name: kletterArenaLink('scharpenbeul_listersee', 'Scharpenbeul') },
        { lat: 51.151336, lng: 7.9973096, name: kletterArenaLink('borghausen', 'BorghauserWand') },
        { lat: 51.435193, lng: 8.3528023, name: kletterArenaLink('hillenberg', 'Hillenberg Warstein') },
        { lat: 51.35167, lng: 8.410359, name: kletterArenaLink('klettergebiet_bestwig', 'Am Bahnchen Bestwig') },
        { lat: 51.24097, lng: 8.4757293, name: kletterArenaLink('meisterstein', 'Meisterstein bei Siedlinghausen') },
        { lat: 51.160375, lng: 8.478397, name: kletterArenaLink('winterberg', 'Steinkuhle Neuastenberg') },
        { lat: 51.126606, lng: 8.6029966, name: kletterArenaLink('klettergebiet_steinschab', 'Steinschab') },
        { lat: 51.057327, lng: 8.2855595, name: kletterArenaLink('klettergebiet_kapplerstein', 'Kapplerstein') },
        { lat: 51.2702256, lng: 8.1891064, name: kletterArenaLink('klettergebiet_wennefelsen', 'Wennefelsen') }
      ]
    }
    return <GMap {...p} />
  }

  if (str === 'RentalView') {
    return <RentalView />
  }

  console.log('Unknown Component:', str)
  return <span>"{str}" not found</span>
}

const MarkdownPage = ({ onLoad, page, section }) => {
  const markDownOnLoad = onLoad || function () {}
  const [content, setContent] = useState('')

  useEffect(markDownOnLoad, [])

  useEffect(() => {
    getPage(page, section)
      .then((c) => {
        setContent(c)
        markDownOnLoad()
      })
      .catch(console.error)
  }, [page, section])

  const renderLink = (props) => {
    const href = props.href.toLowerCase()
    if (href.startsWith('http://') || href.startsWith('https://')) {
      return (
        <a
          href={props.href}
          target='_blank'
          className={s.contentLink}
        >
          {props.children}
        </a>
      )
    }

    return (
      <NavLink
        className={s.contentLink}
        to={'/content/' + props.href}
      >
        {props.children}
      </NavLink>
    )
  }

  const renderImg = (props) => {
    const src = props.src.replace('winter', SEASON).replace('summer', SEASON)
    return (
      <img
        alt={props.alt}
        src={src}
        className={props.className}
        onLoad={markDownOnLoad}
      />
    )
  }

  const transformImageUrl = (input, _, node) => (node.tagName === 'img' ? config.API_URL + 'fotos/' + input : input)

  // h6 is missused for react components. This is a workaround
  const h6 = (props) => {
    const reactComponent = componentFactory(props.children)
    return <div>{reactComponent}</div>
  }

  return (
    <ReactMarkdown
      rehypePlugins={[rehypeRaw]}
      urlTransform={transformImageUrl}
      components={{
        img: renderImg,
        a: renderLink,
        h6: h6
      }}
    >
      {content}
    </ReactMarkdown>
  )
}

MarkdownPage.propTypes = {
  content: PropTypes.any,
  onLoad: PropTypes.func
}

export default MarkdownPage
