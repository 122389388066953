export function extractContentFromFlickrResponse(resp) {
  const photosRaw = resp?.photoset?.photo || []
  const title = resp?.photoset?.title

  const minScreenSize = Math.min(screen.width, screen.height)
  const isMobile = minScreenSize <= 600

  const getSrc = (photo) => photo.url_l || photo.url_z || photo.url_h
  const getThumb = (photo) => photo.url_n
  const getThumbSize = (photo) => ({
    thumbnailHeight: parseInt(photo.height_n),
    thumbnailWidth: parseInt(photo.width_n)
  })

  const getMobileSrc = (photo) => photo.url_c || photo.url_z
  const getMobileThumb = (photo) => photo.url_s
  const getMobileThumbSize = (photo) => ({
    thumbnailHeight: parseInt(photo.height_s),
    thumbnailWidth: parseInt(photo.width_s)
  })

  const normalPhotos = photosRaw.map((p) => ({
    src: getSrc(p),
    thumbnail: getThumb(p),
    ...getThumbSize(p)
  }))

  const mobilePhotos = photosRaw.map((p) => ({
    src: getMobileSrc(p),
    thumbnail: getMobileThumb(p),
    ...getMobileThumbSize(p)
  }))

  const photos = isMobile ? mobilePhotos : normalPhotos

  return {
    title,
    photos
  }
  /*
      src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
      thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
      thumbnailWidth: 320,
      thumbnailHeight: 174,
      caption: "After Rain (Jeshu John - designerspics.com)"
     */

  // n is thumbNail size
  // display size:  h, b
  // mobile size  c, z
}
// o_dims,url_sq, url_t, url_s, url_m, url_o
// o_dims,url_h,url_n,url_c,url_z,url_l,url_o  // nu
// o_dims,url_h,url_n,url_c,url_z,url_l,url_o,url_sq,url_t,url_s,url_m
// ,url_sq,url_t,url_s,url_m
// https://api.flickr.com/services/rest/?method=flickr.photosets.getList&api_key=ba7be564ff5ccb76f59ef551b65ebe76&user_id=7402523%40N05&format=json&nojsoncallback=1
// const a = {
//   "photoset": {
//     "id": "72157677450407888",
//     "primary": "45911179304",
//     "owner": "7402523@N05",
//     "ownername": "hanno2007",
//     "photo": [
//       {
//         "id": "45911179304",
//         "secret": "b417aeaa46",
//         "server": "7870",
//         "farm": 8,
//         "title": "36789917_977376265720539_7324637918088134656_o",
//         "isprimary": "0",
//         "ispublic": 1,
//         "isfriend": 0,
//         "isfamily": 0,
//         "url_h": "https://farm8.staticflickr.com/7870/45911179304_67c7ebefd6_h.jpg",
//         "height_h": "1600",
//         "width_h": 1200,
//         "url_n": "https://farm8.staticflickr.com/7870/45911179304_b417aeaa46_n.jpg",
//         "height_n": "320",
//         "width_n": 240,
//         "url_c": "https://farm8.staticflickr.com/7870/45911179304_b417aeaa46_c.jpg",
//         "height_c": "800",
//         "width_c": 600,
//         "url_z": "https://farm8.staticflickr.com/7870/45911179304_b417aeaa46_z.jpg",
//         "height_z": "640",
//         "width_z": "480",
//         "url_l": "https://farm8.staticflickr.com/7870/45911179304_b417aeaa46_b.jpg",
//         "height_l": "1024",
//         "width_l": "768",
//         "url_o": "https://farm8.staticflickr.com/7870/45911179304_2500171888_o.jpg",
//         "height_o": "1600",
//         "width_o": "1200",
//         "url_sq": "https://farm8.staticflickr.com/7870/45911179304_b417aeaa46_s.jpg",
//         "height_sq": 75,
//         "width_sq": 75,
//         "url_t": "https://farm8.staticflickr.com/7870/45911179304_b417aeaa46_t.jpg",
//         "height_t": "100",
//         "width_t": "75",
//         "url_s": "https://farm8.staticflickr.com/7870/45911179304_b417aeaa46_m.jpg",
//         "height_s": "240",
//         "width_s": "180",
//         "url_m": "https://farm8.staticflickr.com/7870/45911179304_b417aeaa46.jpg",
//         "height_m": "500",
//         "width_m": "375"
//       },
//     ]
//   }
// };
