import React, { useEffect } from 'react'
import { useState } from 'react'
import { Gallery } from 'react-grid-gallery'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import PropTypes from 'prop-types'
import { getPhotoSet } from '../../gateways/flickr'

import s from './flickrPhotoSet.module.css'

/**
 * props wil contain properties: title and photos
 */
export default function PhotoSet(props) {
  const [images, setImages] = useState([])
  const [title, setTitle] = useState('')

  useEffect(() => {
    const fetchPhotoSet = async () => {
      const set = await getPhotoSet(props.setId)
      setTitle(set?.title)

      let result = set?.photos.map((photo) => ({
        src: photo.thumbnail,
        original: photo.src
      }))

      // {
      //     "src": "https://live.staticflickr.com/65535/50299329277_8e11e59e9e_b.jpg",
      //     "thumbnail": "https://live.staticflickr.com/65535/50299329277_8e11e59e9e_n.jpg",
      //     "thumbnailHeight": 240,
      //     "thumbnailWidth": 320
      // }
      setImages(result || [])
    }
    fetchPhotoSet().catch(console.error)
  }, [])

  const [lightboxImages, setLightboxImages] = useState([])

  const rotate = (arr, count = 1) => [...arr.slice(count, arr.length), ...arr.slice(0, count)]

  const handleClick = (i) => {
    const lightboxImages = rotate(images, i).map((image) => ({
      src: image.original
    }))
    setLightboxImages(lightboxImages)
  }
  const handleClose = () => setLightboxImages([])

  return (
    <div className={s.album}>
      <h3>{title}</h3>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      <Lightbox
        open={lightboxImages.length > 0}
        close={handleClose}
        slides={lightboxImages}
      />
    </div>
  )
}

PhotoSet.propTypes = {
  setId: PropTypes.string
}
