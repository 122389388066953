/**
 * A link to a certain page, an anchor tag
 */

import styled from 'styled-components'

const A = styled.a`
  color: #007bff;
`

export default A
